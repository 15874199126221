.main-portfolio-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .prev-next-btn-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 5rem 0;

    .btn-cont {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 1.4rem;
        font-family: 'Aboreto', cursive;
        border-bottom: 0.2rem solid #ccc;
        padding: 0.5rem;
        cursor: pointer;
        transition: 0.5s all;
        &:hover {
          color: var(--highlight-primary);
          transform: scale(1.1);
        }
      }
    }
  }

  .content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    animation: fadeIn 1s ease-in;

    .top-cont {
      width: 80%;
      display: flex;
      justify-content: space-between;
      margin-top: -9rem;

      @include devices(tablet) {
        margin-top: 2rem;
        width: 90%;
      }

      .left-cont {
        width: 55%;

        @include devices(tablet) {
          width: 100%;
        }

        .main-head {
          color: #e4baa1;
          font-family: 'Aboreto', cursive;
          font-size: 6rem;
          margin-bottom: 4rem;
        }

        .content {
          font-size: 1.4rem;
          margin: 2rem 0;
        }
      }

      .right-cont {
        width: 40%;
        height: 70rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: 12rem;

        @include devices(tablet) {
          display: none;
        }

        img {
          height: 68rem;
          width: 100%;
          object-fit: cover;
          z-index: 99;
          margin-left: 7rem;
          border-radius: 0.3rem;
          box-shadow: var(--box-shadow);
        }
      }

      .second-one {
        margin-top: 0;
      }
    }

    .bottom-cont {
      width: 100%;
      height: 60rem;
      display: flex;
      justify-content: flex-end;

      @include devices(tablet) {
        height: 30rem;
      }

      img {
        width: 95%;
        height: 100%;
        margin-top: -10rem;
        object-fit: cover;
        box-shadow: var(--box-shadow);

        @include devices(tablet) {
          margin-top: 0;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
