.services-cont {
  width: 100%;
  padding: 10rem 0;
  display: flex;
  background-color: var(--bg-2);
  justify-content: center;

  @include devices(tablet) {
    flex-direction: column;
  }

  .header-cont {
    display: flex;
    width: 25%;

    @include devices(tablet) {
      width: 100%;
      align-items: center;
    }

    .main-head {
      font-size: 5rem;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 1rem;
      letter-spacing: 0.2rem;
      width: 100%;
      text-align: left;

      @include devices(tablet) {
        text-align: center;
      }
    }
  }

  .right-cont {
    width: 50%;
    // border-top: 0.1rem solid var(--text-secondary);
    margin-left: 3rem;

    @include devices(tablet) {
      width: 100%;
      margin-left: 0;
      margin-top: 2rem;
    }

    .contents {
      display: flex;
      flex-direction: column;
      width: 100%;

      .content {
        padding: 3rem 0;
        display: flex;
        border-bottom: 0.1rem solid var(--text-secondary);
        width: 100%;

        @include devices(tablet) {
          padding: 3rem;
        }

        span {
          color: #fff;
          font-size: 1.6rem;
          text-align: left;
        }

        .text-1 {
          font-size: 2rem;
          @include devices(tablet) {
            font-size: 1.2rem;
            width: 40%;
          }
        }

        .text-2 {
          font-size: 2rem;
          margin-left: 15rem;
          @include devices(tablet) {
            margin-left: 1rem;
            font-size: 1.2rem;
            width: 40% !important;
          }
        }
      }
    }

    .content-left {
      margin-left: 10%;
      @include devices(tablet) {
        margin-left: 0;
      }
    }

    .content-right {
      margin-left: -10%;

      @include devices(tablet) {
        margin-left: 0;
      }
    }
  }
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 600px;
  }
  100% {
    background-position: 600px;
  }
}
