.home-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .banner-cont {
    width: 100%;
    height: 90vh;
    position: relative;
    background-color: #000;

    @include devices(tablet) {
      height: 98vh;
    }

    .video {
      width: 100%;
      height: 70vh !important;
      object-fit: cover;
      top: 22% !important;
      background-position: top;

      @include devices(tablet) {
        height: 76vh !important;
      }
    }

    .content-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      height: 100%;
      z-index: 99;

      .text {
        color: var(--text-white);
        font-size: 10.5rem;
        width: 100%;
        text-align: center;
        font-family: 'times';
        line-height: 11rem;
        letter-spacing: 1rem;
        margin-top: -10rem;
        font-weight: 400;
        width: 60%;
        font-family: 'Aboreto', cursive !important;

        @include devices(tablet) {
          font-size: 4.8rem;
          width: 100%;
          line-height: 6rem;
          margin-top: -6rem;
        }
      }

      .slogan {
        color: var(--text-white);
        font-size: 1.2rem;
        width: 100%;
        text-align: center;
        font-family: 'Raleway', sans-serif;
        line-height: 2.8rem;
        letter-spacing: 0.2rem;
        margin-top: 4rem;
        width: 20%;

        @include devices(tablet) {
          font-size: 1.4rem;
          width: 60%;
        }
      }

      .contact-us {
        margin-top: 3rem;
        color: var(--text-white) !important;
        font-size: 1rem;
        width: 100%;
        text-align: center;
        line-height: 2.8rem;
        letter-spacing: 0.3rem;
        width: 10%;
        text-transform: uppercase;
        border-bottom: 0.1rem solid white;
        cursor: pointer;
        text-decoration: none !important;

        @include devices(tablet) {
          font-size: 1.2rem;
          width: 40%;
        }
      }
    }

    .content-overlay {
      width: 100%;
      height: 70vh;
      position: absolute;
      top: 22%;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      background-color: rgba($color: #000000, $alpha: 0.6);

      @include devices(tablet) {
        height: 76vh;
      }

      .scroller-cont {
        display: flex;
        justify-content: flex-end;
        width: 80%;
        position: absolute;
        top: 85%;

        .scroll-animate-view {
          display: flex;
          align-items: center;
          cursor: pointer;

          .content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .cont-1 {
              font-size: 1.4rem;
              letter-spacing: 0.2rem;
              color: var(--text-secondary);
            }

            .cont-2 {
              font-size: 1.2rem;
              margin-top: 0.5rem;
              color: var(--text-secondary);
              letter-spacing: 0.2rem;
            }
          }

          .icon {
            font-size: 3rem;
            margin-left: 1rem;
            color: var(--text-secondary);
            animation: up-down 0.5s infinite alternate;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1441px) {
  .home-contents-cont {
    max-width: 1440px;
    margin: 0 auto;
  }
}

@keyframes up-down {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(8px);
  }
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 600px;
  }
  100% {
    background-position: 600px;
  }
}
