.portfolio-main-cont {
  width: 100%;
  padding: 10rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header-cont {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;

    .completd {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 1rem;
      letter-spacing: 0.2rem;
      width: 10%;

      @include devices(tablet) {
        display: none;
      }
    }

    .main-head {
      font-size: 5rem;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 1rem;
      letter-spacing: 0.2rem;
      width: 55%;
      text-align: left;
      font-family: 'Aboreto', cursive !important;

      @include devices(tablet) {
        width: 100%;
        text-align: center;
      }
    }

    .link {
      font-size: 1rem;
      text-transform: uppercase;
      margin-top: 1rem;
      letter-spacing: 0.2rem;
      width: 5%;
      text-align: end;
      border-bottom: 0.1rem solid var(--bg-2);
      cursor: pointer;

      @include devices(tablet) {
        display: none;
      }
    }
  }

  .portfolio-cont {
    background-color: white;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 3rem;

    @include devices(tablet) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .left-cont {
      width: 49%;
      border-top: 0.1rem solid var(--bg-2);
      padding-top: 4rem;

      @include devices(tablet) {
        width: 90%;
        border-top: none;
      }

      .image-cont {
        width: 100%;
        height: 76rem;
        transform: scale(1rem);

        @include devices(tablet) {
          height: 25rem;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 0.3rem;
          cursor: pointer;

          &:hover {
            transform: scale(1.03);
            transition: 0.5s all;
          }

          @include devices(tablet) {
            object-fit: contain;
          }
        }
      }
    }

    .right-cont {
      width: 49%;
      border-top: 0.1rem solid var(--bg-2);
      padding-top: 4rem;

      @include devices(tablet) {
        width: 90%;
        margin-left: 0;
        border-top: none;
      }

      .cont {
        width: 100%;
        height: 35rem;
        margin-bottom: 6rem;

        @include devices(tablet) {
          margin-bottom: 10rem;
          height: 20rem;
        }

        .image-cont {
          width: 100%;
          height: 100%;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 0.3rem;
            cursor: pointer;
            &:hover {
              transform: scale(1.03);
              transition: 0.5s all;
            }

            @include devices(tablet) {
              object-fit: contain;
            }
          }
        }
      }
    }

    .captions {
      font-size: 1.3rem;
      text-transform: uppercase;
      font-weight: 500;
      margin-top: 1rem;
      letter-spacing: 0.2rem;
    }

    .captions-gray {
      font-size: 1rem;
      letter-spacing: 0.2rem;
      color: #aaa;
    }
  }
}

@media only screen and (min-width: 1441px) {
  .portfolio-cont {
    max-width: 1440px;
    margin: 0 auto;
  }
}
