.about-us-cont {
  width: 100%;
  padding: 10rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include devices(tablet) {
    padding: 5rem 0 0 0;
  }

  .header-cont {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;

    .completd {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 1rem;
      letter-spacing: 0.2rem;
      width: 10%;

      @include devices(tablet) {
        display: none;
      }
    }

    .main-head {
      font-size: 6.2rem;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 1rem;
      letter-spacing: 0.2rem;
      width: 15%;
      text-align: center;
      z-index: 99;
      font-family: 'Aboreto', cursive !important;

      @include devices(tablet) {
        width: 100%;
        text-align: center;
      }
    }

    .link {
      font-size: 1rem;
      text-transform: uppercase;
      margin-top: 1rem;
      letter-spacing: 0.2rem;
      width: 5%;
      text-align: end;

      @include devices(tablet) {
        display: none;
      }
    }
  }

  .portfolio-cont {
    background-color: white;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: -5rem;

    @include devices(tablet) {
      flex-direction: column;
      align-items: center;
    }

    .left-cont {
      width: 75%;
      padding-top: 2rem;

      @include devices(tablet) {
        width: 100%;
      }

      .image-cont {
        width: 100%;
        height: 45rem;

        @include devices(tablet) {
          height: 25rem;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .right-cont {
      width: 20%;
      margin-left: 2rem;
      border-top: 0.1rem solid var(--bg-2);
      padding-top: 2rem;

      @include devices(tablet) {
        width: 90%;
        margin-left: 0;
      }

      .cont {
        width: 80%;
        height: 35rem;
        margin-bottom: 5rem;
        display: flex;
        flex-direction: column;

        @include devices(tablet) {
          width: 100%;
        }

        .text {
          font-size: 1.2rem;
          letter-spacing: 0.1rem;
          margin-bottom: 1rem;
        }
      }
    }

    .captions {
      font-size: 1.3rem;
      text-transform: uppercase;
      font-weight: 500;
      margin-top: 1rem;
      letter-spacing: 0.2rem;
    }
  }
}

@media only screen and (min-width: 1441px) {
  .portfolio-cont {
    max-width: 1440px;
    margin: 0 auto;
  }
}
