.Header {
  top: 0;
  height: 7rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9rem 3rem;
  z-index: 999999;
  background-color: transparent;
  transition-property: all;
  transition-duration: 0.3s;
  background: #000;

  @include devices(tablet) {
    padding: 6rem 1rem;
  }

  .Logo {
    display: flex;
    align-items: center;
    border-right: 0.1rem solid #e4baa1;
    padding: 2.5rem;

    @include devices(tablet) {
      padding: 1rem;
    }

    .img1 {
      height: 8rem;
      object-fit: contain;
      @include devices(tablet) {
        height: 6rem;
      }
    }
  }
}

.navOpened {
  background-color: var(--bg-1);
}

.Header-scrolled {
  padding: 2rem 4rem;
  transition: all 0.5s ease;
  background: white;

  @include devices(tablet) {
    padding: 6rem 2rem;
  }

  .Logo {
    border-right: none;
    padding: 0;
    background-color: transparent;
    .img1 {
      height: 7rem;

      @include devices(tablet) {
        height: 6rem;
      }
    }
  }
}

.nav-items {
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin-top: 2rem;
  margin-right: 3rem;
  a {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    transition: 0.5s;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.2rem;

    @include devices(tablet) {
      font-size: 1rem;
    }
  }

  @include devices(tablet) {
    width: 60%;
    margin-right: 1rem;
  }
}
